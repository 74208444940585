<template>
  <div class="billing-address">
    <vs-checkbox class="mb-3"  v-model="hasInvoiceAddress">
        {{$t("differing-invoice-address")}}</vs-checkbox>

        <vs-input v-if="hasInvoiceAddress"
          class="inputx mb-3" :label-placeholder="$t('customer')+'*'"
          v-model="customer.invoice_address.name" :maxlength="50"/>
        <vs-input v-if="hasInvoiceAddress"
          class="inputx mb-2" :label-placeholder="$t('addition')"
          v-model="customer.invoice_address.additional"/>
        <vs-input v-if="hasInvoiceAddress"
          class="inputx mb-2" :label-placeholder="$t('street')+'*'"
          v-model="customer.invoice_address.street_address" :maxlength="50"/>
        <vs-input v-if="hasInvoiceAddress"
          class="inputx mb-2" :label-placeholder="$t('zip-code')+'*'"
          v-model="customer.invoice_address.zip_code"/>
          <vs-input v-if="hasInvoiceAddress"
            class="inputx mb-2" :label-placeholder="$t('city')+'*'"
          v-model="customer.invoice_address.city" :maxlength="50"/>
        <vs-select v-if="hasInvoiceAddress"
          class="inputx mb-2" :label="$t('Bundesland')+'*'"
          v-model="customer.invoice_address.state" >
          <vs-select-item v-for="e, idx in bundeslandData" :key="idx" :value="e" :text="e" />
        </vs-select>
        <vs-select v-if="hasInvoiceAddress"
          class="inputx mb-2" :label="$t('country')+'*'"
          v-model="customer.invoice_address.country">
          <vs-select-item v-for="e, idx in landData" :key="idx" :value="e" :text="e" />
        </vs-select>

      <vs-row class="w-100">
        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
          <vs-button class="pop-btn organ-btn" color="primary" type="filled" icon="done"
              @click="editCustomer()">
              {{$t('save')}}</vs-button>
        </vs-col>
      </vs-row>

  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash'
import errorNotifier from "@/plugins/errorNotifier";

export default Vue.extend({
  name: 'BillingAddress',
  async mounted() {
    await this.$store.dispatch('customer/loadCustomer', this.user.customer_id)
    this.customer = _.cloneDeep(this.$store.getters['customer/getCustomer'])

    this.hasInvoiceAddress = false
    if (this.customer.hasOwnProperty('invoice_address')) {
      if (this.customer.invoice_address.hasOwnProperty('name')) {
        if (this.customer.invoice_address.name) {
          this.hasInvoiceAddress = true
        }
      }
    }
  },  

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

 
  data() {
    return {
      customer: {
        name: null,
        invoice_address: {
          name: null || '',
          additional: null || '',
          street_address: null || '',
          zip_code: null || '',
          city: null || '',
          state: null || '',
          country: null || '',
        }
      },
      hasInvoiceAddress: false,
      landData: ['Deutschland', 'Österreich'],
      bundeslandData: ['Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg','Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen',
      'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen']
    }
  },

  methods: {
    async editCustomer() {
      if (!this.hasInvoiceAddress) {
        this.customer.invoice_address = {
          street_address: null || '',
          additional: null || '',
          zip_code: null || '',
          state: null || '',
          name: null || '',
          city: null || '',
          country: null || '',
        }
        this.hasInvoiceAddress = false
      }
      if (this.hasInvoiceAddress&&!this.validateInputs()) {
        return;
      }

      let status = await this.$store.dispatch('customer/updateCustomer', this.customer)
      if (status === 200) {
        this.$vs.notify({
          color: "success",
          position: "bottom-right",
          title: "Daten wurden gespeichert",
        });
      } else {
        errorNotifier(status)
      }      
    },

    validateInputs() {
      //  Check Name
      if ((!this.customer.invoice_address.name) || (this.customer.invoice_address.name.trim() === '')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Name eingeben.",
          text: "",
        });
        return false;
      }

      if ((!this.customer.invoice_address.street_address) || (this.customer.invoice_address.street_address.trim() === '')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Straße eingeben.",
          text: "",
        });
        return false;
      }
      
      if ((!this.customer.invoice_address.zip_code) || (this.customer.invoice_address.zip_code.trim() === '')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte PLZ eingeben.",
          text: "",
        });
        return false;
      }
      if (isNaN(this.customer.invoice_address.zip_code)) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Ungültige PLZ",
          text: "PLZ fehlerhaft, bitte überprüfen!",
        });
        return false;
      }
      if ((this.customer.invoice_address.zip_code.length != 5) && (this.customer.invoice_address.country==='Deutschland')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Ungültige PLZ",
          text: "PLZ fehlerhaft, bitte überprüfen!",
        });
        return false;
      }
      if ((this.customer.invoice_address.zip_code.length != 4) && (this.customer.invoice_address.country==='Österreich')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Ungültige PLZ",
          text: "PLZ fehlerhaft, bitte überprüfen!",
        });
        return false;
      }

      if ((!this.customer.invoice_address.city) || (this.customer.invoice_address.city.trim() === '')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Ort eingeben.",
          text: "",
        });
        return false;
      }

      if ((!this.customer.invoice_address.state) || (this.customer.invoice_address.state.trim() === '')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Bundesland eingeben.",
          text: "",
        });
        return false;
      }

      if ((!this.customer.invoice_address.country) || (this.customer.invoice_address.country.trim() === '')) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Bitte Land eingeben.",
          text: "",
        });
        return false;
      }
      return true;
    }
  }
});
</script>
<style>

</style>
